import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import("../pages/u-index.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("../pages/u-profile.vue"),
    },
    {
      path: "/categories",
      name: "categories",
      component: () => import("../pages/u-categories.vue"),
    },
    {
      path: "/categories-inner",
      name: "categories-inner",
      component: () => import("../pages/u-categories-inner.vue"),
    },
    {
      path: "/user",
      name: "user",
      component: () => import("../pages/u-user.vue"),
    },
    {
      path: "/edit",
      name: "edit",
      component: () => import("../pages/u-edit.vue"),
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("../pages/u-auth.vue"),
    },
    {
      path: "/user-img",
      name: "user-img",
      component: () => import("../pages/u-user-img.vue"),
    },
    // { path: "/:pathMatch(.*)*",
    //   component: () => import("../pages/g-404.vue") ,
    // },
  ],
});

export default router;
